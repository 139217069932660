import "./styles.css";

document.getElementById("app").innerHTML = `
<div class="container">
<div style="grid-column: 2;">
<h1>Howdy!</h1>
<div>
  Moving you to <a href="https://sequin.io">https://sequin.io</a>.
</div>
<br/>
<div>You should be redirected automatically in a few seconds.</div>
</div>
</div>
`;
